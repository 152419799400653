import React from 'react'
import ReactPlayer from "react-player"

function VideoPlayer({ videoId, handleVideoReady }) {
    return (
        <ReactPlayer onReady={handleVideoReady} width="70vw" height="70vh" url={`https://www.youtube.com/watch?v=${videoId}`} controls />
    )
}

export default VideoPlayer
