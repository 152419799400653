import React, { use, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Container, TextField, Button, Typography, Box, Modal } from "@mui/material";
import "./App.css"
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import ReCAPTCHA from "react-google-recaptcha";
import VideoPlayer from "./VideoPlayer";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    overFlow: "hiiden",
    outline: "none",
    border: "none",

};


const FormComponent = () => {
    const [captchaValue, setCaptchaValue] = useState(null);
    const [isCaptchaEntered, setIsCaptchaEnterdown] = useState(false)
    const [isFormSubmit, setIsFormSubmit] = useState(false)
    const [open, setOpen] = useState(false);
    const [videoId, setVideoId] = useState("")
    const [currentUrl, setCurrentUrl] = useState("")
    const [locationUrl, setLocationUrl] = useState("")
    const [isLoading, setIsLoading] = useState(true);
    const [isThankYou, setIsThankYou] = useState(false)
    const [buttonLabel, setButtonLabel] = useState("Submit")
    const [isContactUs, setIsContactUs] = useState(false)


    const handleVideoReady = () => {
        setIsLoading(false); // Set loading to false when video is ready
    };


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false)

    useEffect(() => {
        const firstPath = new URL(window.location.href).pathname.split('/')[1] || '';
        const remainingPath = new URL(window.location.href).pathname.split('/').slice(2).join('/')
        setCurrentUrl(firstPath)
        setLocationUrl(remainingPath)

        if (firstPath == "contact-us") {

            setIsContactUs(true)
        } else {
            setIsContactUs(false)
        }

        if (firstPath === "data-sheet" || firstPath == "case-study" || firstPath == "whitepaper" || firstPath == "ebook") {
            setButtonLabel("Download")

        } else if (firstPath == "webinars") {
            setButtonLabel("Watch Now")

        } else {
            setButtonLabel("Submit")

        }


    }, [])






    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();





    const onSubmit = (data) => {
        setCaptchaValue(captchaValue);

        if (!captchaValue) {
            setIsCaptchaEnterdown(true);
            return;
        }

        // Salesforce Web-to-Lead Submission
        const iframe = document.createElement("iframe");
        iframe.name = "hidden_iframe";
        iframe.style.display = "none";
        document.body.appendChild(iframe);

        const sfForm = document.createElement("form");
        sfForm.method = "POST";
        sfForm.action = "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8";
        sfForm.target = "hidden_iframe";

        const sfFields = {
            oid: "00DdN00000JAQRJ",
            retURL: "",
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            company: data.company,
            phone: data.phone,
            title: currentUrl,
            Location_URL__c: locationUrl,
            ...(isContactUs && { description: data.description }),
            lead_source: "Web",
        };



        Object.entries(sfFields).forEach(([key, value]) => {
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = value;
            sfForm.appendChild(input);
        });

        document.body.appendChild(sfForm);
        sfForm.submit();

        setTimeout(() => {
            document.body.removeChild(sfForm);
            document.body.removeChild(iframe);
        }, 3000);

        setIsFormSubmit(true);

        const allowedBasePaths = [
            "/data-sheet/",
            "/case-study/",
            "/whitepaper/",
            "/ebook"
        ];
        const videoPath = ["/webinars/"];
        const currentPath = window.location.pathname.toLowerCase();

        const isDownloadAllowed = allowedBasePaths.some(base =>
            currentPath.startsWith(base)
        );

        const isVideoPlay = videoPath.some(base =>
            currentPath.startsWith(base)
        );

        if (isDownloadAllowed) {
            setIsThankYou(false);
            let updatedUrl = window.location.href.replace(
                "https://www.xfilespro.com/",
                "https://www.xfilespro.com/PDF/"
            );

            if (updatedUrl.endsWith("/")) {
                updatedUrl = updatedUrl.slice(0, -1);
            }

            const finalUrl = updatedUrl + ".pdf";
            window.open(finalUrl, "_blank");
        } else if (isVideoPlay) {
            setIsThankYou(false);
            setOpen(true);

            const queryParams = new URLSearchParams(window.location.search);
            const id = queryParams.get("id");

            if (id) {
                setVideoId(id);
            }
        } else {
            setIsThankYou(true);
        }

        reset();
        setCaptchaValue(null);
    };








    return (
        <Container maxWidth="xs" sx={{ mt: 5 }}>
            {isFormSubmit ? <Box sx={{
                background: "#F4F4F4",
                padding: 2,
                borderRadius: 2,
                boxShadow: 3,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}>
                <Typography variant="h4" sx={{ fontWeight: "500", color: "#DE1B24" }}>
                    Thank you!
                </Typography>
                <CheckCircleIcon
                    sx={{ width: "150px", height: "200px", color: "green", marginBottom: "15px", marginTop: "15px" }} />
                <Typography variant="p" sx={{ letterSpacing: "0.5px" }}>
                    {isThankYou ? "Thank you for filling out the form. We'll connect with you shortly." : null}
                </Typography>
            </Box> : <Box
                sx={{
                    background: "#F4F4F4",
                    padding: 2,
                    borderRadius: 2,
                    boxShadow: 3,
                    textAlign: "center",
                }}
            >
                <Typography variant="h5" sx={{ fontWeight: "bold", }}>
                    Fill in Your Details
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        // label="First Name *"
                        placeholder="First Name *"
                        margin="normal"
                        {...register("firstName", { required: "First name is required" })}
                        error={!!errors.firstName}
                        helperText={errors.firstName?.message}
                        sx={{

                            "& .MuiInputBase-input": {
                                padding: "10px",
                                width: "320px",
                                fontSize: ".8rem",
                                background: "#ffff"
                            },
                        }}
                    />
                    <TextField
                        // label="Last Name *"
                        placeholder="Last Name *"
                        margin="normal"
                        {...register("lastName", { required: "Last name is required" })}
                        error={!!errors.lastName}
                        helperText={errors.lastName?.message}
                        sx={{

                            "& .MuiInputBase-input": {
                                padding: "10px",
                                width: "320px",
                                fontSize: ".8rem",
                                background: "#ffff"
                                // Ensures text inside is properly styled
                            },
                        }}
                    />
                    <TextField
                        // label="Business Email *"
                        placeholder="Business Email *"

                        margin="normal"
                        type="email"
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: "Invalid email format",
                            },
                            validate: (value) =>
                                !/(gmail\.com|yahoo\.com|hotmail\.com|outlook\.com|aol\.com)$/i.test(value) ||
                                " Please enter a business email.",
                        })}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        sx={{

                            "& .MuiInputBase-input": {
                                padding: "10px",
                                width: "320px",
                                fontSize: ".8rem",
                                background: "#ffff"
                                // Ensures text inside is properly styled
                            },
                        }}
                    />
                    <TextField
                        placeholder="Phone"
                        margin="normal"
                        {...register("phone")}
                        sx={{

                            "& .MuiInputBase-input": {
                                padding: "10px",
                                width: "320px",
                                fontSize: ".8rem",
                                background: "#ffff"
                                // Ensures text inside is properly styled
                            },
                        }}
                    />
                    <TextField
                        // label="Company"
                        placeholder="Company"
                        margin="normal"
                        {...register("company")}
                        sx={{

                            "& .MuiInputBase-input": {
                                padding: "10px",
                                width: "320px",
                                fontSize: ".8rem",
                                background: "#ffff"
                                // Ensures text inside is properly styled
                            },
                        }}
                    />

                    {isContactUs && <TextField
                        id="outlined-multiline-static"
                        // label="Multiline"
                        placeholder="Comment"
                        margin="normal"
                        {...register("description")}
                        multiline
                        rows={4}
                        style={{ padding: "0px" }}
                        sx={{

                            "& .MuiInputBase-input": {
                                padding: "10px",
                                width: "320px",
                                fontSize: ".8rem",
                                background: "#ffff"
                                // Ensures text inside is properly styled
                            },
                        }}

                    />}
                    <div style={{
                        transform: "scale(1.1)",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "10px",
                    }}>

                        <ReCAPTCHA sitekey="6LfTNQcrAAAAANj35p-3QtYBhRUbJgSp6xsvNx4L" onChange={setCaptchaValue} />
                        {isCaptchaEntered && <p style={{ color: "#d32f2f", fontWeight: 400, fontSize: "0.75rem", letterSpacing: "0.03333em;", marginTop: "3px", textAlign: "left", marginLeft: "0px", marginRight: "18px" }}>Please complete the reCAPTCHA verification.</p>}
                    </div>


                    <Button type="submit" variant="contained" fullWidth sx={{ mt: 2, background: "linear-gradient(90deg, #A20303 0%, #DE1B24 100%)", width: "344px" }}>
                        {buttonLabel}
                    </Button>
                </form>
                <Typography variant="body2" sx={{
                    mt: 2, fontSize: "0.8rem", color: "#666", width: "320px", marginLeft: "auto", marginRight: "auto",
                }}>
                    By filling out the form, you consent to XfilePro’s use of personal data
                    according to our <a href="https://www.xfilespro.com/privacy-statement" style={{ color: "#F7374F", fontWeight: "bold" }}>Privacy Policy</a>.
                </Typography>
            </Box>}

            <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
                <Box sx={style}>

                    {isLoading ? (
                        <CircularProgress
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 10,
                            }}
                        />
                    ) : null}
                    <VideoPlayer handleVideoReady={handleVideoReady} videoId={videoId} />
                    <ClearIcon className="closeIcon" onClick={handleClose} />
                </Box>
            </Modal>


        </Container >
    );
};

export default FormComponent;
